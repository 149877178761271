import Vue from "vue";
import axios from "axios";
import qs from "qs";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import App from "./App.vue";
import router from "./router";
import "./assets/css/global.css";
import store from "./store/store";
import Cookies from "js-cookie";
// import VueNativeSock from "vue-native-websocket";
//为axios添加拦截器
axios.interceptors.request.use((config) => {
  config.headers.Authorization = window.localStorage.getItem("token");
  return config; //最后必须返回config
});

Vue.prototype.$Cookies = Cookies;
Vue.prototype.$qs = qs;
Vue.prototype.$axios = axios;
Vue.use(ElementUI);
Vue.prototype.TITLE = "棉花厂管理系统";
Vue.config.productionTip = false;

// Vue.prototype.HOST = "https://yq.api.zaillusion.cn/api";
// Vue.prototype.HOST = "http://localhost:9163/api";
// Vue.prototype.WS_HOST = "ws://localhost:9163/ws";

axios.defaults.baseURL =
  process.env.NODE_ENV === "production"
    ? process.env.VUE_APP_PROD_DOMAIN
    : process.env.VUE_APP_DEV_DOMAIN;
// Vue.prototype.userPermission = -1;

// Vue.use(VueNativeSock, "ws://localhost:9163/ws", {
//   format: "json",
//   reconnection: true,
// });

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
