<template>
  <div>
    <h3>Hello</h3>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Welcome",
};
</script>

<style scoped></style>
