<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>订单管理</el-breadcrumb-item>
      <el-breadcrumb-item>所有订单</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片视图 -->
    <el-card>
      <el-row :gutter="20"
        ><!-- gutter是指定每个分栏的间隔 -->
        <!-- 分栏一共占24格，给搜索框7格，添加按钮4格 -->
        <el-col :span="7">
          <!-- 搜索与添加区域 -->
          <el-input placeholder="请输入内容">
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button
            type="primary"
            @click="handleNewOrder()"
            v-if="permission > 0"
            >指派新订单</el-button
          >
        </el-col>
      </el-row>
      <el-button
        size="mini"
        style="margin-top: 20px"
        type="success"
        @click="getOrderList"
        >刷新</el-button
      >
      <el-table
        :data="orderList"
        border
        stripe
        :default-sort="{ prop: 'assignmentTime', order: 'descending' }"
        ><!-- 带边框、斑马纹 -->
        <el-table-column label="订单号" prop="orderNumber"></el-table-column>
        <el-table-column
          label="来源"
          prop="origin"
          width="70"
        ></el-table-column>
        <el-table-column
          label="游戏名"
          prop="game"
          width="100"
        ></el-table-column>
        <el-table-column label="要求" prop="requirements"></el-table-column>
        <el-table-column label="价格" prop="price" width="70"></el-table-column>
        <el-table-column
          label="派单时间"
          prop="assignmentTime"
          width="130"
        ></el-table-column>
        <el-table-column
          label="完成时间"
          prop="completionTime"
          width="130"
        ></el-table-column>
        <el-table-column label="打手" prop="user" width="70"></el-table-column>
        <el-table-column
          label="派单人"
          prop="distributorName"
          width="70"
        ></el-table-column>
        <el-table-column label="订单状态" width="80">
          <template slot-scope="{ row }">
            <el-tag type="info" v-if="row.status === 0">
              {{ statusText(row.status) }}
            </el-tag>
            <el-tag type="warning" v-if="row.status === 1 || row.status === 2">
              {{ statusText(row.status) }}
            </el-tag>
            <el-tag type="success" v-if="row.status === 3">
              {{ statusText(row.status) }}
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="150" v-if="permission > 0">
          <template slot-scope="scope">
            <el-button type="primary" @click="handleEdit(scope)" size="mini"
              >编辑</el-button
            >
            <el-button
              type="danger"
              @click="handleDelete(scope.row.id)"
              size="mini"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pagenum"
        :page-size="queryInfo.pagesize"
        layout="total, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <el-dialog title="订单详情" :visible.sync="dialogVisible" width="30%">
      <el-form ref="orderInfo" :model="orderInfo" label-width="80px">
        <el-form-item label="订单号">
          <el-input v-model="orderInfo.orderNumber"></el-input>
        </el-form-item>

        <el-form-item label="来源">
          <el-input v-model="orderInfo.origin"></el-input>
        </el-form-item>

        <el-form-item label="要求">
          <el-input v-model="orderInfo.requirements"></el-input>
        </el-form-item>

        <el-form-item label="游戏名">
          <el-select v-model="orderInfo.gameId">
            <el-option
              v-for="item in gameInfo"
              :key="item.id"
              :label="item.game"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="订单状态" v-if="dialogStatus === 'edit'">
          <el-select v-model="orderInfo.status">
            <el-option
              v-for="item in status"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="价格">
          <el-input v-model="orderInfo.price"></el-input>
        </el-form-item>

        <el-form-item label="指派打手">
          <el-select v-model="orderInfo.userId">
            <el-option
              v-for="item in userInfo"
              :key="item.userId"
              :label="item.user"
              :value="item.userId"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <template v-if="dialogStatus === 'edit'">
          <el-button type="primary" @click="handleSendEditForm()"
            >确 定</el-button
          >
        </template>
        <template v-if="dialogStatus === 'add'">
          <el-button type="primary" @click="handleSendNewOrder()"
            >确 定</el-button
          >
        </template>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "AllOrder",
  data() {
    return {
      queryInfo: {
        query: "",
        pagenum: 1,
        pagesize: 10,
      },
      dialogVisible: false,
      orderList: [],
      orderInfo: {},
      gameInfo: [],
      userInfo: [],
      total: 0,
      dialogStatus: "edit",
      status: [
        { id: 0, value: "未完成" },
        { id: 1, value: "代练中" },
        { id: 2, value: "未结算" },
        { id: 3, value: "已结算" },
      ],
      statusMap: {
        0: "未完成",
        1: "代练中",
        2: "未结算",
        3: "已结算",
      },
    };
  },
  methods: {
    getUserList() {
      this.$axios.get("/user/list").then((res) => {
        this.userInfo = res.data.data;
      });
    },
    getGameInfo() {
      this.$axios.get("/game/list").then((res) => {
        this.gameInfo = res.data.data;
      });
    },
    async queryOrderById(id) {
      this.$axios.get("/orders/queryOrderById?id=" + id).then((res) => {
        this.orderInfo = res.data.data;
      });
    },
    statusText(status) {
      return this.statusMap[status] || "未知状态";
    },
    getOrderList() {
      this.$axios.get("/orders/all", { params: this.queryInfo }).then((res) => {
        this.orderList = res.data.data;
        this.total = res.data.total;
      });
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pagenum = newPage;
      this.getOrderList();
    },
    handleNewOrder() {
      this.dialogStatus = "add";
      this.dialogVisible = true;
      this.orderInfo = {};
    },
    handleSendNewOrder() {
      this.orderInfo.distributorId = this.getUserId;
      this.$axios
        .post("/orders/add", this.orderInfo)
        .then((res) => {
          if (res.data.code === 10000) {
            this.$message.success("指派成功");
            this.dialogVisible = false;
            this.getOrderList();
          }
        })
        .catch((err) => {
          this.$message.error("指派失败：" + err.response.data.msg);
        });
    },
    handleEdit(scope) {
      this.dialogStatus = "edit";
      this.dialogVisible = true;
      this.queryOrderById(scope.row.id);
    },
    handleSendEditForm() {
      this.$axios
        .post("/orders/editById", this.orderInfo)
        .then((res) => {
          if (res.data.code === 10000) {
            this.$message.success("编辑成功");
            this.dialogVisible = false;
            this.getOrderList();
          }
        })
        .catch((err) => {
          this.$message.error("编辑失败：" + err.response.data.msg);
        });
    },
    handleDelete(id) {
      this.$confirm("确认删除该订单？此操作不可逆！", "小子，你在玩火", {
        confirmButtonText: "删除",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
            .get("/orders/delete?id=" + id)
            .then((res) => {
              if (res.data.code === 10000) {
                this.$message.success(res.data.msg);
              } else {
                this.$message.error(res.data.msg);
              }
              this.getOrderList();
            })
            .catch((err) => {
              this.$message.error("操作失败: " + err);
              this.getOrderList();
            });
        })
        .catch(() => {
          this.$message.info("操作取消");
          this.getOrderList();
        });
    },
  },
  mounted() {
    this.getOrderList();
    this.getGameInfo();
    this.getUserList();
  },

  computed: {
    permission() {
      return this.$Cookies.get("userPermission");
    },
    getUserId() {
      return this.$Cookies.get("userId");
    },
  },
};
</script>

<style scoped></style>
