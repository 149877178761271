<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>订单管理</el-breadcrumb-item>
      <el-breadcrumb-item>我的订单</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片视图 -->
    <el-card>
      <el-row :gutter="20"
        ><!-- gutter是指定每个分栏的间隔 -->
        <!-- 分栏一共占24格，给搜索框7格，添加按钮4格 -->
        <el-col :span="7">
          <!-- 搜索与添加区域 -->
          <el-input placeholder="请输入内容">
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </el-col>
      </el-row>
      <el-button
        size="mini"
        style="margin-top: 20px"
        type="success"
        @click="getOrderList"
        >刷新</el-button
      >
      <el-table
        :data="orderList"
        v-loading="dataLoading"
        border
        stripe
        :default-sort="{ prop: 'assignmentTime', order: 'descending' }"
        ><!-- 带边框、斑马纹 -->
        <el-table-column label="订单号" prop="orderNumber"></el-table-column>
        <el-table-column
          label="来源"
          prop="origin"
          width="70"
        ></el-table-column>
        <el-table-column
          label="游戏名"
          prop="game"
          width="100"
        ></el-table-column>
        <el-table-column label="要求" prop="requirements"></el-table-column>
        <el-table-column label="价格" prop="price" width="70"></el-table-column>
        <el-table-column
          label="派单时间"
          prop="assignmentTime"
          width="130"
        ></el-table-column>
        <el-table-column
          label="完成时间"
          prop="completionTime"
          width="130"
        ></el-table-column>
        <el-table-column label="打手" prop="user" width="70"></el-table-column>
        <el-table-column
          label="派单人"
          prop="distributorName"
          width="70"
        ></el-table-column>
        <el-table-column label="订单状态" width="80">
          <template slot-scope="{ row }">
            <el-tag
              type="info"
              v-if="row.status === 0"
              @click="completeOrder(row.id, row.status)"
            >
              {{ statusText(row.status) }}
            </el-tag>
            <el-tag
              type="warning"
              v-else-if="row.status === 1"
              @click="completeOrder(row.id, row.status)"
            >
              {{ statusText(row.status) }}
            </el-tag>
            <el-tag type="warning" v-else-if="row.status === 2">
              {{ statusText(row.status) }}
            </el-tag>
            <el-tag type="success" v-else-if="row.status === 3">
              {{ statusText(row.status) }}
            </el-tag>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pagenum"
        :page-size="queryInfo.pagesize"
        layout="total, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "myOrders",
  data() {
    return {
      dataLoading: true,
      queryInfo: {
        query: "",
        pagenum: 1,
        pagesize: 10,
        userId: -1,
      },
      dialogVisible: false,
      orderList: [],
      orderInfo: {},
      total: 0,
      dialogStatus: "edit",
      status: [
        { id: 0, value: "未完成" },
        { id: 1, value: "代练中" },
        { id: 2, value: "未结算" },
        { id: 3, value: "已结算" },
      ],
      statusMap: {
        0: "未完成",
        1: "代练中",
        2: "未结算",
        3: "已结算",
      },
    };
  },
  methods: {
    completeOrder(id, status) {
      status++;
      // 打开弹窗确认
      this.$confirm(
        "确定要将订单状态改为<label style='color: red'>" +
          this.statusMap[status] +
          "</label>吗？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          dangerouslyUseHTMLString: true,
        }
      )
        .then(() => {
          this.dataLoading = true;
          this.$axios
            .get("/orders/editOrderStatusById?id=" + id + "&status=" + status)
            .then((res) => {
              if (res.data.code === 10000) {
                this.$message({
                  type: "success",
                  message: "变更成功!",
                });

                this.getOrderList();
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消变更",
          });
        });
    },
    async queryOrderById(id) {
      this.$axios.get("/orders/queryOrderById?id=" + id).then((res) => {
        this.orderInfo = res.data.data;
      });
    },
    statusText(status) {
      return this.statusMap[status] || "未知状态";
    },
    getOrderList() {
      this.dataLoading = true;
      this.queryInfo.userId = this.getUserId;
      this.$axios
        .get("/orders/MyOrders", { params: this.queryInfo })
        .then((res) => {
          this.orderList = res.data.data;
          this.total = res.data.total;
          this.dataLoading = false;
        });
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pagenum = newPage;
      this.getOrderList();
    },
  },
  mounted() {
    this.getOrderList();
    // 每隔 5 秒钟执行一次数据刷新
    this.refreshTimer = setInterval(this.getOrderList, 10000);
  },
  computed: {
    permission() {
      return this.$Cookies.get("userPermission");
    },
    getUserId() {
      return this.$Cookies.get("userId");
    },
  },
};
</script>

<style scoped></style>
