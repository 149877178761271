import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Login from "../components/Login.vue";
import Home from "../components/Home.vue";
import Welcome from "../components/Welcome.vue";
import AllOrders from "../components/order/AllOrders.vue";
import MyOrders from "../components/order/MyOrders.vue";
Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/home",
    component: Home,
    redirect: "welcome",
    children: [
      { path: "/welcome", component: Welcome },
      { path: "/allOrders", component: AllOrders },
      { path: "/myOrders", component: MyOrders },
    ],
  },
];
// 路由守卫

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = "棉花厂管理系统";
  //获取token
  const tokenStr = localStorage.getItem("token");
  //如果用户访问登录页，直接放行
  if (to.path === "/login") {
    if (!tokenStr) {
      return next();
    }
    next("home");
  }
  //没有token，强制跳转到登录页面
  if (!tokenStr) return next("/login");
  next(); //有token，直接放行
});
export default router;
