<template>
  <div :xl="6" :lg="7" class="bg-login">
    <!--logo-->
    <el-image :src="require('@/assets/logo.png')" class="logo" />
    <!--标题-->
    <el-row type="flex" class="row-bg row-two" justify="center" align="middle">
      <el-col :span="6"></el-col>
      <el-col :span="6">
        <!--标题-->
        <h1 class="title">棉花厂管理系统</h1>
      </el-col>
      <el-col :span="6"></el-col>
    </el-row>
    <!--form表单-->
    <el-row type="flex" class="row-bg card" justify="center" align="bottom">
      <el-col :span="7" class="login-card">
        <!--loginForm-->
        <el-form
          :model="loginForm"
          :rules="rules"
          ref="loginForm"
          label-width="21%"
          class="loginForm"
        >
          <el-form-item label="账户" prop="username" style="width: 380px">
            <el-input v-model="loginForm.username"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password" style="width: 380px">
            <el-input type="password" v-model="loginForm.password"></el-input>
          </el-form-item>
          <!--          <el-form-item label="记住密码" prop="remember">-->
          <!--            <el-switch v-model="loginForm.remember"></el-switch>-->
          <!--          </el-form-item>-->
          <el-form-item class="btn-ground">
            <el-button type="primary" @click="submitForm('loginForm')"
              >登录
            </el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Login",
  data() {
    return {
      // 表单信息
      loginForm: {
        // 账户数据
        username: "",
        // 密码数据
        password: "",
        // 记住密码
        // remember: false,
      },
      // 表单验证
      rules: {
        // 设置账户效验规则
        username: [
          { required: true, message: "请输入账户", trigger: "blur" },
          {
            min: 3,
            max: 10,
            message: "长度在 3 到 10 个字符的账户",
            trigger: "blur",
          },
        ],
        // 设置密码效验规则
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 6,
            max: 15,
            message: "长度在 6 到 15 个字符的密码",
            trigger: "blur",
          },
        ],
        // 设置验证码效验规则
        code: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          { min: 5, max: 5, message: "长度为 5 个字符", trigger: "blur" },
        ],
      },
      // 绑定验证码图片
      codeImg: null,
    };
  },
  methods: {
    // 提交表单
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 表单验证成功
          this.$axios
            .post("/user/login", this.loginForm)
            .then((res) => {
              /* 登陆成功 */
              const token = res.data.token;
              localStorage.setItem("token", token);
              /* 跳转页面 */
              this.$router.push("/home");
            })
            .catch((error) => {
              // 登录失败，显示错误提示
              if (error.response && error.response.status === 401) {
                // 401状态码表示未授权，即验证失败
                const errorMessage = error.response.data;
                // 可以使用Element UI的Message组件来显示错误提示
                this.$message.error(errorMessage);
              } else {
                // 其他错误情况，显示通用错误提示
                this.$message.error("发生了一些错误，请稍后再试。");
              }
            });
        }
      });
    },
    // 重置表单
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style scoped>
.bg-login {
  height: 100%;
  /*background-image: url("../assets/backgroud.jpg");*/
  background-color: #4b5765;
  background-size: 200%;
}

.btn-ground {
  text-align: center;
}

.logo {
  margin: 30px;
  height: 70px;
  width: 70px;
  position: fixed;
}

.title {
  text-shadow: -3px 3px 1px #5f565e;
  text-align: center;
  margin-top: 60%;
  color: #41b9a6;
  font-size: 40px;
}

.login-card {
  background-color: #ffffff;
  opacity: 0.9;
  box-shadow: 0 0 20px #ffffff;
  border-radius: 10px;
  padding: 40px 40px 30px 15px;
  width: auto;
}
</style>
