// store.js (或 store.ts 如果你使用 TypeScript)
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    userPermission: -1, // 定义全局变量
    userId: -1,
  },
  mutations: {
    setUserPermission(state, userPermission) {
      state.userPermission = userPermission; // 更新全局变量的值
    },
    setUserId(state, userId) {
      state.userId = userId; // 更新全局变量的值
    },
  },
  getters: {
    getUserPermission(state) {
      return state.userPermission; // 获取全局变量的值
    },
    getUserId(state) {
      return state.userId; // 获取全局变量的值
    },
  },
});

export default store;
